<div class="mat-dialog-container">
  <h1 mat-dialog-title>Create Processor</h1>
  <mat-dialog-content>
    <form class="add-member-form" [formGroup]="processorProfileFormGroup" (ngSubmit)="onSubmit()">
      <div class="form-group">
        <div class="form-field form-field-input">
          <mat-label class="form-field-label">Email:</mat-label>
          <mat-form-field appearance="outline">
            <input matInput type="text" [formControl]="loginEmailFormControl" required>
            <mat-error *ngIf="loginEmailFormControl.invalid">
              Please enter a valid email.
            </mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="form-group">
        <div class="form-field form-field-input">
          <mat-label class="form-field-label">First Name:</mat-label>
          <mat-form-field appearance="outline">
            <input matInput type="text" [formControl]="firstNameFormControl" required>
            <mat-error *ngIf="firstNameFormControl?.invalid">
              Required
            </mat-error>
          </mat-form-field>
        </div>
        <div class="form-field form-field-input">
          <mat-label class="form-field-label">Last Name:</mat-label>
          <mat-form-field appearance="outline">
            <input matInput type="text" [formControl]="lastNameFormControl" required>
            <mat-error *ngIf="lastNameFormControl.invalid">
              Required
            </mat-error>
          </mat-form-field>
        </div>
        <div class="form-field form-field-checkbox">
          <span class="sub-section-title">Allow Xero Invoicing</span>
          <mat-checkbox color="primary"
                        [formControl]="invoicingPermissionsFormControl"
          >
          </mat-checkbox>
        </div>
        <div class="form-field form-field-checkbox">
          <span class="sub-section-title">View Cost Pricing</span>
          <mat-checkbox color="primary"
                        [formControl]="costViewingPermissionsFormControl"
          >
          </mat-checkbox>
        </div>
      </div>
      <hr>
      <div class="form-group" [formGroup]="servicePermissionsFormGroup">
        <h3 class="form-group-title">Service Permissions</h3>
        <div class="form-field form-field-checkbox">
          <span class="sub-section-title">Image Services</span>
          <mat-checkbox color="primary"
                        [formControl]="imageServicesFormControl"
          >
          </mat-checkbox>
        </div>
        <div class="form-field form-field-checkbox">
          <span class="sub-section-title">Video Services</span>
          <mat-checkbox color="primary"
                        [formControl]="videoServicesFormControl"
          >
          </mat-checkbox>
        </div>
        <div class="form-field form-field-checkbox">
          <span class="sub-section-title">Floorplan Services</span>
          <mat-checkbox color="primary"
                        [formControl]="floorplanServicesFormControl"
          >
          </mat-checkbox>
        </div>
      </div>
    </form>
  </mat-dialog-content>
  <mat-dialog-actions>
    <button [mat-dialog-close]="true"
            class="btn btn-red cancel-btn"
            mat-button
    >
      Cancel
    </button>
    <button
      mat-raised-button
      type="submit"
      class="btn btn-green"
      (click)="onSubmit()"
    >
      Create Processor
    </button>
  </mat-dialog-actions>
</div>
