import {Component} from '@angular/core';
import {FormControl, FormGroup, Validators,} from '@angular/forms';
import {EMAIL_REGEX} from '../../helpers/regex'
import {MatDialogRef} from '@angular/material/dialog';
import {SnackbarService} from '../../services/snackbar.service'
import {ProcessorsService} from "../../services/processors.service";

@Component({
  selector: 'prism-add-processor',
  templateUrl: './add-processor.component.html',
  styleUrls: ['./add-processor.component.scss']
})
export class AddProcessorComponent {
  processorProfileFormGroup: FormGroup<ProcessorProfileFormGroup>;

  constructor(private processorService: ProcessorsService,
              public dialogRef: MatDialogRef<AddProcessorComponent>,
              private snackbarService: SnackbarService) {
    this.processorProfileFormGroup = new FormGroup({
      loginEmail: new FormControl('', [Validators.required, Validators.pattern(EMAIL_REGEX)]),
      firstName: new FormControl('', [Validators.required]),
      lastName: new FormControl('', [Validators.required]),
      servicePermissions: new FormGroup({
        imageService: new FormControl(false, [Validators.required]),
        videoService: new FormControl(false, [Validators.required]),
        floorplanService: new FormControl(false, [Validators.required]),
      }, [Validators.required]),
      invoicingPermissions: new FormControl(false, [Validators.required]),
      costViewingPermissions: new FormControl(false, [Validators.required]),
    });
  }

  onSubmit() {
    const processorData = this.processorProfileFormGroup.value;
    this.processorService.createProcessor(processorData).subscribe(
      response => {
        this.snackbarService.showSnackbar('Processor Added Successfully');
      });

    this.dialogRef.close();
  }

  get firstNameFormControl() {
    return this.processorProfileFormGroup.get('firstName') as FormControl<string>;
  }

  get lastNameFormControl() {
    return this.processorProfileFormGroup.get('lastName') as FormControl<string>;
  }

  get loginEmailFormControl() {
    return this.processorProfileFormGroup.get('loginEmail') as FormControl<string>;
  }

  get servicePermissionsFormGroup() {
    return this.processorProfileFormGroup.get('servicePermissions') as FormGroup<ProcessorServicePermissionsFormGroup>;
  }

  get imageServicesFormControl() {
    return this.servicePermissionsFormGroup.get('imageService') as FormControl<boolean>;
  }

  get videoServicesFormControl() {
    return this.servicePermissionsFormGroup.get('videoService') as FormControl<boolean>;
  }

  get floorplanServicesFormControl() {
    return this.servicePermissionsFormGroup.get('floorplanService') as FormControl<boolean>;
  }

  get invoicingPermissionsFormControl() {
    return this.processorProfileFormGroup.get('invoicingPermissions') as FormControl<boolean>;
  }

  get costViewingPermissionsFormControl() {
    return this.processorProfileFormGroup.get('costViewingPermissions') as FormControl<boolean>;
  }
}

export interface ProcessorProfileFormGroup {
  firstName: FormControl<string>;
  lastName: FormControl<string>;
  loginEmail: FormControl<string>;
  invoicingPermissions: FormControl<boolean>;
  costViewingPermissions: FormControl<boolean>;
  servicePermissions: FormGroup<ProcessorServicePermissionsFormGroup>,
}

export interface ProcessorServicePermissionsFormGroup {
  videoService: FormControl<boolean>;
  imageService: FormControl<boolean>;
  floorplanService: FormControl<boolean>;
}
