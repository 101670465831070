import {Component, OnDestroy, OnInit} from '@angular/core';
import {Observable, Subject, Subscription, timer} from 'rxjs';
import {take, takeUntil} from 'rxjs/operators';
import {SnackbarService} from "../../../services/snackbar.service";
import {InvoiceService} from "../../../services/invoice.service";
import {XeroConnectionService} from "../../../services/xero-connection.service";
import {MembersService} from "../../../services/members.service";

@Component({
  selector: 'prism-xero-display',
  templateUrl: './xero-display.component.html',
  styleUrls: ['./xero-display.component.scss']
})
export class XeroDisplayComponent implements OnInit, OnDestroy {
  subDestroyer$: Subject<void>;
  loadingQueueCount: boolean;
  reconBusySingle$: Observable<boolean>;
  reconBusyQueue$: Observable<boolean>;
  displayXeroControl: boolean;
  xeroExpiryTimerSubject$: Observable<Observable<any>>;
  xeroExpiryTimer$: Observable<any>;
  organisation$: Observable<any>;
  queueHasError$: Observable<boolean>;
  xeroLoading$: Observable<boolean>;
  loadingQueue$: Observable<boolean>;
  getXeroConnectionDataSub: Subscription;
  members: any[];
  invoiceCompleted: number;
  invoicingProgress: number;
  invoicingInProgress: boolean;

  constructor(private snackbarService: SnackbarService,
              private invoicesService: InvoiceService,
              private xeroConnectionService: XeroConnectionService,
              private membersService: MembersService) {

    this.subDestroyer$ = new Subject<void>();
    this.loadingQueueCount = true;
    this.reconBusySingle$ = this.invoicesService.reconBusySingle$;
    this.reconBusyQueue$ = this.invoicesService.reconBusyQueue$;
    this.displayXeroControl = false;
    this.xeroExpiryTimerSubject$ = this.xeroConnectionService.getXeroExpiryTimer();
    this.organisation$ = this.xeroConnectionService.organisation$;
    this.queueHasError$ = this.invoicesService.queueHasErrors$;
    this.xeroLoading$ = this.xeroConnectionService.xeroLoading$;
    this.loadingQueue$ = this.invoicesService.loadingQueue$;
    this.invoicingProgress = 0;
    this.invoiceCompleted = 0;
    this.invoicingInProgress = false;
  }

  ngOnInit() {
    this.xeroExpiryTimerSubject$
      .subscribe(timer => {
        this.xeroExpiryTimer$ = timer;
      });

    this.getXeroConnectionDataSub = this.xeroConnectionService.getXeroConnection()
      .pipe(
        takeUntil(this.subDestroyer$)
      )
      .subscribe(
        () => {
        },
        () => {
          this.xeroExpiryTimer$ = timer(0);
        }
      );
  }

  connectToXero() {
    this.snackbarService.showSnackbar('Establishing connection with Xero...');
    this.xeroConnectionService.getToken()
      .pipe(
        takeUntil(this.subDestroyer$)
      )
      .subscribe();
  }

  createMemberInvoices() {
    const sessionHasExpired = this.xeroConnectionService.sessionExpired;
    if (!sessionHasExpired) {

      this.invoicingInProgress = true;
      this.membersService.getActiveMembers()
        .subscribe((members) => {
          this.members = members || [];
          this.members.forEach((member) => {
            this.invoicesService.createMemberInvoice(member.memberId)
              .pipe(take(1))
              .subscribe(
                () => {
                },
                (err) => {
                  console.error(err)
                  this.snackbarService.handleError(`Failed to invoice ${member.firstName} ${member.lastName}.`)
                }, () => {
                  this.incrementProgressBar();
                }
              )
          })
        })
    }
  }

  incrementProgressBar() {
    this.invoiceCompleted++;
    this.invoicingProgress = Number(((this.invoiceCompleted / this.members.length) * 100).toFixed());
    if (this.invoiceCompleted === this.members.length) {
      this.invoicingInProgress = false;
      this.invoicingProgress = 0;
      this.invoiceCompleted = 0;
    }
  }

  toggleXeroDisplay() {
    this.displayXeroControl = !this.displayXeroControl;
  }

  ngOnDestroy() {
    this.subDestroyer$.next();
  }
}
