import {Component, OnInit} from '@angular/core';
import {UntypedFormBuilder, FormGroup, Validators, FormControl} from '@angular/forms';
import {Inject, OnDestroy} from '@angular/core';
import {BehaviorSubject, Subscription} from 'rxjs';
import {SnackbarService} from '../../services/snackbar.service';
import {AuthenticationService} from '../../services/authentication.service'
import {MembersService} from '../../services/members.service'
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {EMAIL_REGEX} from '../../helpers/regex';

@Component({
  selector: 'prism-edit-member',
  templateUrl: './edit-member.component.html',
  styleUrls: ['./edit-member.component.scss']
})
export class EditMemberComponent implements OnInit, OnDestroy {
  loadingMemberData: boolean;
  freshUntouchedProfileData: any;
  memberDataState: any;
  private memberDataSub: Subscription;
  private formChangeSub: Subscription;
  formChanged: BehaviorSubject<boolean>;
  personalInformationForm!: FormGroup;
  isLoading: boolean;

  constructor(private memberService: MembersService,
              private formBuilder: UntypedFormBuilder,
              private snackbarService: SnackbarService,
              private authService: AuthenticationService,
              private dialogRef: MatDialogRef<EditMemberComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any) {
    this.formChanged = new BehaviorSubject<boolean>(true);
    this.personalInformationForm = this.formBuilder.group({
      firstName: new FormControl('', [Validators.required]),
      lastName: new FormControl('', [Validators.required]),
      loginEmail: new FormControl({value: '', disabled: true}),
      secondaryEmail: new FormControl('', [Validators.required, Validators.pattern(EMAIL_REGEX)]),
      contactNumber: new FormControl('', [Validators.required]),
      postalAddress: new FormControl('', [Validators.required]),
      companyName: new FormControl('', [Validators.required]),
      companyId: new FormControl('', [Validators.required]),
      acceptedPlatformPolicies: new FormControl(null),
      acceptedUserPolicies: new FormControl(null)
    });
    this.loadingMemberData = true;
    this.isLoading = true;
  }

  ngOnInit() {
    this.memberDataSub = this.memberService.getMember(this.data).subscribe(
      (responseData: any) => {
        this.isLoading = false;

        this.memberDataState = responseData.data;
        this.populateFields(responseData.data)
      },
      (error: Error) => {
        this.closeDialog()
        this.snackbarService.handleError("This is not an authenticated member.")
      },
      () => {
      }
    )
    this.formChangeSub = this.personalInformationForm.valueChanges.subscribe((data) => {
      let {
        firstName,
        lastName,
        contactNumber,
        secondaryEmail,
        postalAddress,
        companyName,
        companyId,
        acceptedPlatformPolicies,
        acceptedUserPolicies
      } = this.memberDataState
      if (data.contactNumber === contactNumber && data.firstName === firstName && data.lastName === lastName && data.postalAddress === postalAddress && data.secondaryEmail === secondaryEmail
        && data.companyName === companyName && data.companyId === companyId && data.acceptedUserPolicies === acceptedUserPolicies && data.acceptedPlatformPolicies === acceptedPlatformPolicies
      ) {
        this.formChanged.next(false);
      } else {
        this.formChanged.next(true);
      }
    })
  }

  closeDialog() {
    this.dialogRef.close({});
  }

  save(data: any) {
    const {firstName, lastName, loginEmail} = data;
    this.dialogRef.close({
      firstName: firstName,
      lastName: lastName,
      loginEmail: loginEmail
    });
  }

  saveAccount() {
    this.personalInformationForm.disable();
    this.loadingMemberData = true;
    this.memberService.updateMemberData(this.personalRawFormData)
      .subscribe((responseData: any) => {
        this.save(responseData.data)
        this.snackbarService.showSnackbar('Members list has been updated! Refresh to see changes.');
      });
  }

  populateFields(memberData: any) {
    const {
      firstName,
      lastName,
      loginEmail,
      secondaryEmail,
      contactNumber,
      postalAddress,
      companyName,
      companyId,
      acceptedPlatformPolicies,
      acceptedUserPolicies
    } = memberData;
    this.freshUntouchedProfileData = {
      firstName,
      lastName,
      loginEmail,
      secondaryEmail,
      contactNumber,
      postalAddress,
      companyName,
      companyId,
      acceptedPlatformPolicies,
      acceptedUserPolicies
    };

    this.firstNameControl?.setValue(firstName);
    this.lastNameControl?.setValue(lastName);
    this.loginEmailControl?.setValue(loginEmail);
    this.secondaryEmailControl?.setValue(secondaryEmail);
    this.contactNumberControl?.setValue(contactNumber);
    this.postalAddressControl?.setValue(postalAddress);
    this.companyNameControl?.setValue(companyName);
    this.companyIdControl?.setValue(companyId);
    this.personalInformationForm.updateValueAndValidity();
    this.personalInformationForm.enable();
    if (acceptedUserPolicies === false) {
      this.platformPoliciesControl?.setValue(false);
    } else {
      this.platformPoliciesControl?.setValue(true);
    }
    if (acceptedPlatformPolicies === false) {
      this.userPoliciesControl?.setValue(false);
    } else {
      this.userPoliciesControl?.setValue(true);
    }
    this.loginEmailControl?.disable({onlySelf: true});
    this.loadingMemberData = false;
  }

  get firstNameControl() {
    return this.personalInformationForm.get('firstName');
  }

  get lastNameControl() {
    return this.personalInformationForm.get('lastName');
  }

  get loginEmailControl() {
    return this.personalInformationForm.get('loginEmail');
  }

  get secondaryEmailControl() {
    return this.personalInformationForm.get('secondaryEmail');
  }

  get contactNumberControl() {
    return this.personalInformationForm.get('contactNumber');
  }

  get postalAddressControl() {
    return this.personalInformationForm.get('postalAddress');
  }

  get companyNameControl() {
    return this.personalInformationForm.get('companyName');
  }

  get companyIdControl() {
    return this.personalInformationForm.get('companyId');
  }

  get platformPoliciesControl() {
    return this.personalInformationForm.get('acceptedPlatformPolicies');
  }

  get userPoliciesControl() {
    return this.personalInformationForm.get('acceptedUserPolicies');
  }

  get personalRawFormData() {
    return this.personalInformationForm.getRawValue();
  }

  ngOnDestroy() {
    this.formChangeSub.unsubscribe();
    this.memberDataSub.unsubscribe();
  }
}
